import React from 'react'

const PageBanner = ({pageTitle}) => {
    return (
        <div className="contactpage-title-area">
            <div className="container d-flex justify-content-around">
                <div className="page-title-content align-self-center">
                    <h1 className="">{pageTitle}</h1>
                </div>
            </div>
        </div>
    )
}

export default PageBanner;