import React from 'react'

const ContactInfo = () => {
    return (
        <div className="contact-info-area pb-70">
            <div className="container pt-5 pb-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="contact-info-box">
                            {/* <div className="back-icon">
                                <i className='bx bx-map'></i>
                            </div> */}
                            <div className="icon rounded-circle">
                                <i className='bx bx-map'></i>
                            </div>
                            <h3>Our Address</h3>
                            <p>12 Fetlock Drive NEWBURY, RG14 7WR United Kingdom</p>
                        </div>
                    </div>

                    {/* <div className="col-lg-4 col-md-6">
                        <div className="contact-info-box">
                           
                            <div className="icon rounded-circle">
                                <i className='bx bx-phone-call'></i>
                            </div>
                            <h3>Call us</h3>
                            
                            <p>Mobile:</p>
                            <p><a href="tel:1300 842 108">1300 842 108</a></p>
                            
                        </div>
                    </div> */}

                    <div className="col-lg-6 col-md-6 offset-lg-0">
                        <div className="contact-info-box">
                            {/* <div className="back-icon">
                                <i className='bx bx-time-five'></i>
                            </div> */}
                            <div className="icon rounded-circle">
                                <i className='bx bx-envelope'></i>
                            </div>
                            <h3>Mail us</h3>
                            {/* <p>Monday - Friday: 09:00 - 20:00</p>
                            <p>Sunday & Saturday: 10:30 - 22:00</p> */}
                            {/* <p>E-mail:</p> */}
                            <p><a href="mailto:accounts@inovett.co.uk">accounts@inovett.co.uk</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo